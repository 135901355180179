
import Vue from "vue";
import { getBlog } from "@/services/blog";
import ContentComponent from "@/components/ContentComponent.vue";
import { Blog } from "@/models/blog";
import Component from "vue-class-component";
import { Route } from "vue-router/types/router";
import { Watch } from "vue-property-decorator";
import { config } from "@/config";
import { getMeta } from "@/utils/api";

@Component({
  name: "PostPage",
  components: {
    ContentComponent
  },
  metaInfo: {
    title: getMeta()["title"] || ""
  }
})
export default class PostPage extends Vue {
  slug = "home";
  blog: Blog | null = null;

  async fetchPage(newSlug: string) {
    const loading = this.$vs.loading({
      type: "circles",
      color: "primary",
      text: "Loading...",
      opacity: 1,
      scale: 2
    });
    const blog = await getBlog(newSlug);
    if (blog === null) {
      await this.$router.push({ name: "404" });
    } else {
      this.blog = blog;
      document.title = `${blog.title || ""} - ${getMeta().title}`;
    }
    loading.close();
  }

  mounted() {
    this.slug = this.$route.params.slug;
    if (this.slug === "home") {
      this.fetchPage("home");
    }
  }

  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    this.slug = to.params.slug;
    next();
  }

  @Watch("slug")
  onSlugChanged(value: string, oldValue: string) {
    this.fetchPage(value);
  }
}
