
import Vue from "vue";
import { listBlog } from "@/services/blog";
import InternalBlogCard from "@/components/contents/internal/InternalBlogCard.vue";
import { Blog } from "@/models/blog";
import Component from "vue-class-component";

const BlogPostProps = Vue.extend({
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    InternalBlogCard
  }
});

@Component
export default class BlogPostContent extends BlogPostProps {
  blogs: Array<Blog> = [];

  async mounted() {
    const params = this.content.params || {};
    this.blogs = await listBlog({
      ...params,
      limit: this.content.count || 4
    });
  }
}
