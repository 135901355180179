
import Vue from "vue";
import Component from "vue-class-component";
import applyBase from "@/utils/applyBase";

const CardContentProps = Vue.extend({
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  }
});

@Component
export default class CardContent extends CardContentProps {
  get baseUrl() {
    return applyBase(this.content.image);
  }
}
