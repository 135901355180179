
import Vue from "vue";
import Component from "vue-class-component";
import { Blog } from "@/models/blog";

const InternalBlogCardProps = Vue.extend({
  props: {
    type: String,
    blog: Blog
  }
});

@Component
export default class InternalBlogCard extends InternalBlogCardProps {
  get enabledList(): Array<string> {
    return this.type.split(",");
  }
}
