
import Vue from "vue";
import Component from "vue-class-component";
import { sendContact } from "@/services/contact";

const ContactProps = Vue.extend({
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  }
});

@Component
export default class ContactContent extends ContactProps {
  entries: object = {};

  updateEntry(key: string, value: string) {
    Vue.set(this.entries, key, value);
  }

  async submitEntry() {
    const sent = await sendContact(this.content, this.entries);
    if (sent) {
      this.$vs.notification({
        color: "primary",
        position: null,
        title: "Form Submitted.",
        text:
          "Your form is successfully submitted. We will get back to you soon."
      });
      this.entries = {};
    } else {
      this.$vs.notification({
        color: "warning",
        position: null,
        title: "Form Cannot be submitted.",
        text: "Your form cannot be submitted. Please check the values."
      });
    }
  }
}
