import { render, staticRenderFns } from "./ImageBoxContent.vue?vue&type=template&id=3210701c&scoped=true"
import script from "./ImageBoxContent.vue?vue&type=script&lang=js"
export * from "./ImageBoxContent.vue?vue&type=script&lang=js"
import style0 from "./ImageBoxContent.vue?vue&type=style&index=0&id=3210701c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3210701c",
  null
  
)

export default component.exports